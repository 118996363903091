export default {
    menuElements(state) {
        return state.menuElements;
    },
    isPhoneMenuOpen(state) {
        return state.isPhoneMenuOpen;
    },
    IsFiframeTransform(state) {
        return state.IsFiframeTransform;
    },
    productsCategories(state) {
        return state.productsCategories;
    },
    producers(state) {
        return state.producers;
    },
    fixCategories(state) {
        return state.fixCategories;
    },
    processElements(state) {
        return state.processElements;
    }
}