import { v4 as uuidv4 } from "uuid";

export default [{
        id: uuidv4(),
        name: "Pilarki do drewna",
        desc: "spalinowe, elektryczne, akumulatorowe",
        link: "/serwis-naprawczy/#formularz?firstOpt=pilarki-do-drewna",
        src: require("../../../assets/img/co-naprawiamy/rozłożona-pilarka-łańcuchowa-leżąca-na-drewnianym-stole.jpg"),
        alt: "Rozłożona pilarka łańcuchowa STIHL leżąca na jasno-brązowym, drewniamy stole. W okół niej różne narzędzia do narawy."
    },
    {
        id: uuidv4(),
        name: "Kosy do trawy",
        desc: "spalinowe, elektryczne, akumulatorowe",
        link: "/serwis-naprawczy/#formularz?firstOpt=kosy-do-trawy",
        src: require("../../../assets/img/co-naprawiamy/mężczyzna-trzymający-kosę-do-trawy-marki-stihl.jpg"),
        alt: "Mężczyzna koszący trawę w swoim ogródku kosą do trawy marki STIHL. W tle widać zarys jego domu."
    },
    {
        id: uuidv4(),
        name: "Myjki ciśnieniowe",
        desc: "",
        link: "/serwis-naprawczy/#formularz?firstOpt=myjki-ciśnieniowe",
        src: require("../../../assets/img/co-naprawiamy/mężczyzna-myjący-schody-myjką-ciśnieniową.jpg"),
        alt: "Mężczyzna myjący kamienne schody w jego ogrodzie myjką ciśnieniową marki STIHL. Zjednej strony schodów rosną kszaki, a resztę otacza trawa i małe rośliny"
    },
    {
        id: uuidv4(),
        name: "Nożyce do żywopłotów",
        desc: "",
        link: "/serwis-naprawczy/#formularz?firstOpt=nożyce-do-żywopłotów",
        src: require("../../../assets/img/co-naprawiamy/lekko-starszy-mężczyzna-przycinający-żywopłot-nożycami-do-żywopłoów.jpg"),
        alt: "Lekko starszy mężczyzna przycinający żywopłot nożycami do żywopłoów marki STIHL, który tworzy ścieżki w ogrodzie."
    },
    {
        id: uuidv4(),
        name: "Dmuchay i odkurzacze",
        desc: "do liści",
        link: "/serwis-naprawczy/#formularz?firstOpt=dmuchawy-i-odkurzacze",
        src: require("../../../assets/img/co-naprawiamy/mężczyzna-odkurzający-liście-odkurzaczem-marki-stihl.jpg"),
        alt: "Mężczyzna odukrzający liście z tarasu odkurzaczem marki STIHL. W okół fruwające liście, a zanim trewniany fotel oraz taras."
    },
    {
        id: uuidv4(),
        name: "Kosiarki do trawy",
        desc: "spalinowe, elektryczne, akumulatorowe",
        link: "/serwis-naprawczy/#formularz?firstOpt=kosiarki-do-trawy",
        src: require("../../../assets/img/co-naprawiamy/mężczyzna-koszący-trawnik-kosiarką-stihl.jpg"),
        alt: "Mężczyzna koszący trawnik w ogrodzie kosiarką STIHL. Zanim płot obrośnięty cały kszakami i różnymi roślinami, a obok stojąca, zielona, drewniana ławka"
    },
    {
        id: uuidv4(),
        name: "Kosiarki samojezdne/ciągniczki",
        desc: "",
        link: "/serwis-naprawczy/#formularz?firstOpt=kosiarki-samojezdne-ciągniczki",
        src: require("../../../assets/img/co-naprawiamy/rozłożona-kosiarka-samojezdna-marki-stihl.jpg"),
        alt: "Rozłożona kosiarka zamojezdna marki STIHL, leżąca w kartonie, przy szarej bramie garażowej, zakryta w niektórych miejscach folią bombelkową"
    },
    {
        id: uuidv4(),
        name: "Łuparki do drewna",
        desc: "elektryczne",
        link: "/serwis-naprawczy/#formularz?firstOpt=łuparki-do-drewna",
        src: require("../../../assets/img/co-naprawiamy/żółto-czarna-łuparka-marki-sthil.jpg"),
        alt: "Zółto-czarna łuparka do drewna za kryta od góry kratką i z włożonymi do niej dwoma kawałkami drewna/"
    },
    {
        id: uuidv4(),
        name: "Narzędzia ogrodowe i akcesoria",
        desc: "kuchenne FISKARS",
        link: "/serwis-naprawczy/#formularz?firstOpt=narzędzia-ogrodowe-i-akcesoria",
        src: require("../../../assets/img/co-naprawiamy/nożyce-ogrodowe-oparte-o-ścięte-gałęzie-drzew.jpg"),
        alt: "Nożyce ogrodowe marki STIHL oparte o ściane zrobioną ze ściętych gałęzi drzew"
    },
    {
        id: uuidv4(),
        name: "Zagęszczarki",
        desc: "",
        link: "/serwis-naprawczy/#formularz?firstOpt=zagęszczarki",
        src: require("../../../assets/img/co-naprawiamy/przygotowana-do-naprawienia-żółto-czarna-zagęszczarka.jpg"),
        alt: "Przygotowana do naprawienia żułto czarna zagęszczarka leżąca na drewnianym stole przy białej ścianie"
    },
    {
        id: uuidv4(),
        name: "Glebogryzarki",
        desc: "",
        link: "/serwis-naprawczy/#formularz?firstOpt=glebogryzarki",
        src: require("../../../assets/img/co-naprawiamy/glebogryzarka-spilchniająca-ziemię.jpg"),
        alt: "Glebograyzarka spulchniająca ziemię marki STIHL, w ogródku, przy kamiennym krawężniku, na ktorym rosną małe rośliny."
    },
]