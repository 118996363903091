import { v4 as uuidv4 } from "uuid";

export default [{
        id: uuidv4(),
        name: "Wszyscy",
    },
    {
        id: uuidv4(),
        name: "Stihl",
    },
    {
        id: uuidv4(),
        name: "Viking",
    },
    {
        id: uuidv4(),
        name: "Fiskars",
    },
    {
        id: uuidv4(),
        name: "Briggs & Stratton",
    },
    {
        id: uuidv4(),
        name: "Endress",
    },
    {
        id: uuidv4(),
        name: "Oregon",
    },
]