import menuElements from "./state/menuElements";
// import productsCategories from "./state/products/productsCategories";
import producers from "./state/producers";
import fixCategories from "./state/fixCategories";
import processElements from "./state/processElements";

export default {
    menuElements,
    isPhoneMenuOpen: false,
    producers,
    fixCategories,
    processElements,
}