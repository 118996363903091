import { v4 as uuidv4 } from "uuid";

export default [{
        id: uuidv4(),
        title: "Dostarczasz maszynę",
        desc: "do naszego serwisu",
        position: "left: 85px;top: 85px;",
        class: "how-we-fix__process-element how-we-fix__process-element--left row-start-1"
    },
    {
        id: uuidv4(),
        title: "Dokonujemy wyceny",
        desc: "i przedstawiamy ci ofertę",
        position: "right: 85px;top: 270px;",
        class: "how-we-fix__process-element row-start-2"
    },
    {
        id: uuidv4(),
        title: "Następnie czekamy na",
        desc: "Twoją decyzję odnoścnie naprawy",
        position: "left: 85px;top: 471px;",
        class: "how-we-fix__process-element how-we-fix__process-element--left row-start-3"
    },
    {
        id: uuidv4(),
        title: "Czyścimy Twoją maszynę",
        desc: "lub narzędzie za ciebie :)",
        position: "right: 85px;bottom: 740px;",
        class: "how-we-fix__process-element row-start-4"
    },
    {
        id: uuidv4(),
        title: "Przechodzimy do naprawy",
        desc: "sprzętu",
        position: "left: 85px;bottom: 517px;",
        class: "how-we-fix__process-element how-we-fix__process-element--left row-start-5"
    },
    {
        id: uuidv4(),
        title: "Teraz wystarczy tylko,",
        desc: " że odbierzesz naprawiony sprzęt",
        position: "right: 85px;bottom: 332px;",
        class: "how-we-fix__process-element row-start-6"
    },
    {
        id: uuidv4(),
        title: "I możesz cieszyć się",
        desc: "ponowną pracą maszyny :)",
        position: "left: 85px;bottom: 115px;",
        class: "how-we-fix__process-element how-we-fix__process-element--left row-start-7"
    },
]